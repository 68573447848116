// Set site info
// var siteinfo = document.getElementsByName("siteinfo")[0];
// var site_id = siteinfo.getAttribute("data-siteid");
// var lang = siteinfo.getAttribute("data-sitelang");


// Scroll handler

window.onscroll = function() {
	var body = document.body; //IE 'quirks'
	var doc = document.documentElement; //IE with doctype
	doc = (doc.clientHeight) ? doc : body;

	var distance = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);

	if ( distance === 0 ) {
		document.getElementById('body').classList.add('is-at-top');
	} else {
		document.getElementById('body').classList.remove('is-at-top'); // need to make an addClass function ...
	}
};




$(document).ready(function ()
{	
	/*
	 *	SLIDERS
	 */

	$('.slider-banner').slick({
		autoplay: true,
		autoplaySpeed: 6000,
		fade: true,
		dots: true,
		prevArrow: '<button type="button" class="slick-prev"><i class="icon-angle-left"></i></button>',
		nextArrow: '<button type="button" class="slick-next"><i class="icon-angle-right"></i></button>',
	});

	$('.slider-projects').slick({
		dots: false,
		prevArrow: '<button type="button" class="slick-prev"><i class="icon-angle-left"></i></button>',
		nextArrow: '<button type="button" class="slick-next"><i class="icon-angle-right"></i></button>',
		slidesToShow: 4,
		slidesToScroll: 4,
		responsive: [
		{
			breakpoint: 991,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 3
			}
		},
		{
			breakpoint: 767,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2
			}
		},
		{
			breakpoint: 480,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1
			}
		}],
	});

	// project detail

	$('.slider-big').slick({
		prevArrow: '<button type="button" class="slick-prev"><i class="icon-angle-left"></i></button>',
		nextArrow: '<button type="button" class="slick-next"><i class="icon-angle-right"></i></button>',
		asNavFor: '.slider-thumb',
	});
	$('.slider-thumb').slick({
		prevArrow: '<button type="button" class="slick-prev"><i class="icon-angle-left"></i></button>',
		nextArrow: '<button type="button" class="slick-next"><i class="icon-angle-right"></i></button>',
		asNavFor: '.slider-big',
		slidesToShow: 4,
		slidesToScroll: 1,
		focusOnSelect: true,
		centerMode: true,
		responsive: [
		{
			breakpoint: 991,
			settings: {
				slidesToShow: 3,
			}
		},
		{
			breakpoint: 767,
			settings: {
				slidesToShow: 2,
			}
		},
		{
			breakpoint: 480,
			settings: {
				slidesToShow: 1,
			}
		}]
	});




	/*
	 *	RICH-CONTENT
	 */

	// Lightbox
	$('.lightbox').fancybox({
		overlayOpacity: 0.8,
		overlayColor: 'black',
		padding: 0,
		titleShow: true
	});
	$('.lightbox-media').fancybox({
		overlayOpacity: 0.8,
		overlayColor: 'black',
		padding: 0,
		titleShow: true,
		helpers : {
			media : {}
		}
	});

	// Youtube/Vimeo thumbs
	$(".rc-video__thumb").not('.lightbox-media').click( startVideo );
	$(".rc-video__overlay").not('.lightbox-media').click( startVideo );

	function startVideo(e) {
		var block = $(e.currentTarget);
		var iframe = $('iframe', block.parent());
		var src = iframe.attr('src');

		if (src.indexOf('autoplay=0') > -1) {
			iframe.attr('src', src.replace('autoplay=0','autoplay=1') );
		} else {
			if( src.indexOf('?') > -1 )
			{
				iframe.attr('src', src + '&autoplay=1');
			}
			else
			{
				iframe.attr('src', src + '?autoplay=1');
			}
		}

		$('.rc-video__overlay', block.parent()).fadeOut(150);
		$('.rc-video__thumb', block.parent()).fadeOut(150);
	}

	// Contentblocks slider
	$('.rc-article-slider').slick({
		adaptiveHeight: true,
		prevArrow: '<button type="button" class="slick-prev"><i class="icon-angle-left"></i></button>',
		nextArrow: '<button type="button" class="slick-next"><i class="icon-angle-right"></i></button>',
	});

	// Quote slider
	$('.rc-quote-slider__slick').slick({
		adaptiveHeight: true,
		prevArrow: '<button type="button" class="slick-prev"><i class="icon-angle-left"></i></button>',
		nextArrow: '<button type="button" class="slick-next"><i class="icon-angle-right"></i></button>',
	});
});

$(window).on('load', function ()
{
	// Image slider (options in data-slick)
	$('.rc-image-slider__slick').slick();


	/*
	 *	GRID FILTER
	 */

	$grid = $('#references');
	
	$grid.isotope({
		 // options...
		 itemSelector: '.grid-item',
		 masonry: {
		 }
	});
 
	$select = $('#filter-category');
	$select.on('change', function(e) {
		var selected = $('#filter-category option:selected').val();

		if (selected != '*') {
			selected = '.'+selected;
		}
		
		$grid.isotope({filter: selected});
	});
});



// CAPTCHA
var captcha_validated = false;

function onSubmitreCAPTCHA(response)
{
	captcha_validated = true;
	$('.rc-form form').submit();
}

function validateForm(event)
{
	event.preventDefault();
	var form = this;

	var validator = $(form).data('bs.validator');
	validator.validate();

	if (captcha_validated && !validator.hasErrors()) {
		form.submit();
	} else if(!captcha_validated) {
		grecaptcha.reset();
		grecaptcha.execute();
	}
}
